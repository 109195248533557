import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import PlantOne from "../../Images/Plants/plantOne.jpg";
import PlantTwo from "../../Images/Plants/plantTwo.jpg";
import PlantThree from "../../Images/Plants/plantThree.jpg";
import PlantFour from "../../Images/Plants/plantFour.jpg";
import PlantFive from "../../Images/Plants/plantFive.jpg";
import PlantSix from "../../Images/Plants/plantSix.jpg";
import PlantSeven from "../../Images/Plants/plantSeven.jpg";
import PlantEight from "../../Images/Plants/plantEight.jpg";
import PlantNine from "../../Images/Plants/plantNine.jpg";
import PlantTen from "../../Images/Plants/plantTen.jpg";
import MapImage from "../../Images/Plants/Map.jpg";

import Grid from "@material-ui/core/Grid";
import { Link, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    flexWrap: "nowrap",
  },
  paper: {
    padding: theme.spacing(2),
    // textAlign: "center",
    // color: theme.palette.text.secondary,
  },
  Image: {
    width: "100%",
    maxWidth: "100%",
  },
  mainTitle: {
    textAlign: "center",
    fontWeight: "bolder",
    paddingBottom: theme.spacing(2),
  },
  title: {
    textAlign: "left",
    fontWeight: "bold",
  },
  paragraph: {
    textAlign: "justify",
    paddingBottom: theme.spacing(2),
    marginBottom: "10px",
    textAlign: "justify",
  },
}));

export default function ProjectLayoutWithNav() {
  const classes = useStyles();
  const [selectedPlant, setSelectedPlant] = React.useState(1);

  const setContent = (number) => {
    setSelectedPlant(number);
  };

  const ReferenceLinks = (props) => {
    return (
      <div id="ReferenceLinksHeader">
        Plant 1 (2020). Available at{" "}
        <CustomLink
          link={"https://en.wikipedia.org/wiki/Brunfelsia_latifolia"}
        />
        <br />
        Plant 2 (2020). Available at{" "}
        <CustomLink link={"https://davesgarden.com/guides/pf/go/414/#b"} />
        <br />
        Plant 3 Clasping Heliotrope Wikipedia (2020). Available at{" "}
        <CustomLink
          link={"https://en.m.wikipedia.org/wiki/Heliotropium_amplexicaule"}
        />{" "}
        (accessed 17 March 2020.)
        <br />
        Plant 4 (2020). Available at{" "}
        <CustomLink link={"http://pza.sanbi.org/encephalartos-longifolius"} />
        <br />
        Plant 5 (2020). Available at{" "}
        <CustomLink
          link={
            "https://www.succulents.co.za/aloes/dwarf-aloes/aloe-aristata.php"
          }
        />
        <br />
        Plant 6 Gardenia (2020). available at{" "}
        <CustomLink
          link={"https://www.gardenia.net/plant/aloe-vaombe-malagasy-tree-aloe"}
        />{" "}
        (accessed 17 March 2020.)
        <br />
        Plant 7 Clay Hibiscus Wikipedia (2020). Available at{" "}
        <CustomLink
          link={"https://en.m.wikipedia.org/wiki/Hibiscus_rosa-sinensis"}
        />{" "}
        (accessed 17 March 2020.)
        <br />
        Plant 8 (2020). Available at{" "}
        <CustomLink
          link={
            "https://www.ambius.com/indoor-plants/ultimate-guide-croton-plant-care/"
          }
        />
        <br />
        Plant 9 Purple Heart (2020). Available at{" "}
        <CustomLink
          link={
            "https://homeguides.sfgate.com/care-purple-heart-plants-39710.html"
          }
        />
        <br />
        Plant 10 Jacobs Coat (2020). Available at{" "}
        <CustomLink link={"https://www.britannica.com/plant/Jacobs-coat"} />
      </div>
    );
  };

  const CustomLink = (props) => {
    return (
      <>
        <Link
          onClick={() => {
            window.open(props.link, "_blank");
          }}
        >
          {props.link}
        </Link>
      </>
    );
  };

  const MenuLinks = (props) => {
    return (
      <div className={classes.title}>
        <Link href="#TabPanelBeginning" onClick={() => setContent(1)}>
          1. Brunfelsia Litifolia
        </Link>
        <br />
        <Link href="#TabPanelBeginning" onClick={() => setContent(2)}>
          2. Blister Leaf
        </Link>
        <br />
        <Link href="#TabPanelBeginning" onClick={() => setContent(3)}>
          3. Clasping Heliotrope
        </Link>
        <br />
        <Link href="#TabPanelBeginning" onClick={() => setContent(4)}>
          4. Encephalartos Longifolius
        </Link>
        <br />
        <Link href="#TabPanelBeginning" onClick={() => setContent(5)}>
          5. Lace Aloe
        </Link>
        <br />
        <Link href="#TabPanelBeginning" onClick={() => setContent(6)}>
          6. Malagasy Tree Aloe
        </Link>
        <br />
        <Link href="#TabPanelBeginning" onClick={() => setContent(7)}>
          7. Clay Hibiscus
        </Link>
        <br />
        <Link href="#TabPanelBeginning" onClick={() => setContent(8)}>
          8. Garden Croton
        </Link>
        <br />
        <Link href="#TabPanelBeginning" onClick={() => setContent(9)}>
          9. Purple Heart
        </Link>
        <br />
        <Link href="#TabPanelBeginning" onClick={() => setContent(10)}>
          10. Jacob’s Coat
        </Link>
        <br />
        <Link href="#MapView">Map View</Link>
        <br />
        <Link href="#ReferenceLinksHeader">References</Link>
        <br />
      </div>
    );
  };

  const selectedItem = `plant${selectedPlant}`;
  const info = plantInformation[selectedItem];
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {" "}
          <Typography variant="h3" className={classes.mainTitle}>
            Servey and Landscaping III
          </Typography>
        </Grid>
        <Grid item xs={3}>
          Mickyle Sewlall <br /> 21711223
        </Grid>
        <Grid item xs={3}>
          Faylin Govender <br /> 21636960
        </Grid>
        <Grid item xs={3}>
          Hashlyn Reddy <br /> 21640255
        </Grid>
        <Grid item xs={3}>
          Kamish Pillay <br /> 21640600
        </Grid>
        <Grid item xs={12}>
          <PlantHeaderSection classes={classes} plantInfo={info} />
        </Grid>
        <Grid item xs={5}>
          <PlantImageSection classes={classes} plantInfo={info} />
        </Grid>
        <Grid item xs={4}>
          <PlantDetailsSection classes={classes} plantInfo={info} />
        </Grid>
        <Grid item xs={3}>
          <>
            <MenuLinks />
          </>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" className={classes.title}>
            Map View
          </Typography>
          <MapImageSection classes={classes} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" className={classes.title}>
            Reference List
          </Typography>
          <ReferenceLinks />
        </Grid>
      </Grid>
    </div>
  );
}

const MapImageSection = (props) => {
  const { classes } = props;

  return (
    <div id="MapView">
      <img src={MapImage} className={classes.Image} />
    </div>
  );
};

const PlantHeaderSection = (props) => {
  const { classes, plantInfo } = props;

  return (
    <div id="MainHeader">
      <Typography variant="h4" className={classes.title}>
        {plantInfo.Name}
      </Typography>
    </div>
  );
};

const PlantImageSection = (props) => {
  const { classes, plantInfo } = props;

  return (
    <div>
      <img src={plantInfo.Image} className={classes.Image} />
    </div>
  );
};
const PlantDetailsSection = (props) => {
  const { classes, plantInfo } = props;
  return (
    <div>
      <div id="plantDetails">
        <Typography variant="h6" className={classes.title}>
          {"Description"}
        </Typography>
        <Typography variant="paragraph" className={classes.paragraph}>
          {plantInfo.Description}
        </Typography>

        {plantInfo.Domain ? (
          <>
            <Typography variant="h6" className={classes.title}>
              {"Domain"}
            </Typography>
            <Typography variant="paragraph" className={classes.paragraph}>
              {plantInfo.Domain}
            </Typography>
          </>
        ) : null}

        <Typography variant="h6" className={classes.title}>
          {"Kingdom"}
        </Typography>
        <Typography variant="paragraph" className={classes.paragraph}>
          {plantInfo.Kingdom}
        </Typography>

        {plantInfo.Domain ? (
          <>
            <Typography variant="h6" className={classes.title}>
              {"Branch"}
            </Typography>
            <Typography variant="paragraph" className={classes.paragraph}>
              {plantInfo.Branch}
            </Typography>
          </>
        ) : null}

        <Typography variant="h6" className={classes.title}>
          {"Class"}
        </Typography>
        <Typography variant="paragraph" className={classes.paragraph}>
          {plantInfo.Class}
        </Typography>

        <Typography variant="h6" className={classes.title}>
          {"Order"}
        </Typography>
        <Typography variant="paragraph" className={classes.paragraph}>
          {plantInfo.Order}
        </Typography>

        <Typography variant="h6" className={classes.title}>
          {"Family"}
        </Typography>
        <Typography variant="paragraph" className={classes.paragraph}>
          {plantInfo.Family}
        </Typography>

        <Typography variant="h6" className={classes.title}>
          {"Genus"}
        </Typography>
        <Typography variant="paragraph" className={classes.paragraph}>
          {plantInfo.Genus}
        </Typography>

        {plantInfo.Domain ? (
          <>
            <Typography variant="h6" className={classes.title}>
              {"Species"}
            </Typography>
            <Typography variant="paragraph" className={classes.paragraph}>
              {plantInfo.Species}
            </Typography>
          </>
        ) : null}
      </div>
    </div>
  );
};

const plantInformation = {
  plant1: {
    Image: PlantOne,
    Name: "Brunfelsia Litifolia",
    Description:
      "Brunfelsia latifolia is a 0.2 to 1 m high shrub with strongly branched growth. The branches spring close to the base, are often zigzag-like shaped and covered with leaves. The leaves vary greatly in their shape, they are almost sessile, the petiole is only 1 to 4 mm long, glabrous and dark purple colored. ",
    Kingdom: "Plantae",
    Class: "Magnoliopsida",
    Order: "Solanales",
    Family: "Solanaceae",
    Genus: "Brunfelsia",
  },
  plant2: {
    Image: PlantTwo,
    Name: "Blister Leaf",
    Description:
      "Plumbago auriculata is an evergreen shrub, often grown as a climber, ascending rapidly to 6 m tall by 3 m wide in nature, though much smaller when cultivated as a houseplant. The leaves are a glossy green and grow to 5 cm long. The stems are long, thin, and climbing ",
    Kingdom: "Plantae",
    Class: "Magnoliopsida",
    Order: "Caryophyllales",
    Family: "Plumbaginaceae",
    Genus: "Plumbago",
  },
  plant3: {
    Image: PlantThree,
    Name: "Clasping Heliotrope",
    Description: "",
    Domain: "Forb/Herb",
    Kingdom: "Plantae",
    Branch: "Tracheobionta",
    Class: "Magnoliophyta",
    Order: "Boraginales",
    Family: "Heliotropiaceae",
    Genus: "Heliotropium",
    Species: "Spermatophyta",
  },
  plant4: {
    Image: PlantFour,
    Name: "Encephalartos Longifolius",
    Description:
      "Encephalartos longifolius is a low-growing palm-like cycad in the family Zamiaceae. It is endemic to South Africa and is commonly known as Thunberg's cycad, breadpalm or broodboom. This cycad is listed as near threatened in the IUCN Red List of Threatened Species. ",
    Kingdom: "Plantae",
    Class: "Cycadales",
    Order: "Cycadales",
    Family: "Zamiaceae",
    Genus: "Encephalartos",
  },
  plant5: {
    Image: PlantFive,
    Name: "Lace Aloe",
    Description:
      "The Lace Aloe is a wonderful stemless succulent with soft lanceolate leaves growing in rosette patterns. It has soft green triangular succulent leaves with raised white spots that dominate especially on the underside.",
    Kingdom: "Plantae",
    Class: "Liliopsida",
    Order: "Asparagales",
    Family: "Asphodelaceae",
    Genus: "Aristaloe",
  },
  plant6: {
    Image: PlantSix,
    Name: "Malagasy Tree Aloe",
    Description:
      "The Malagasy tree aloe is a large evergreen plant that has a rosette of curved, dark green, long leaves. The thick, fleshy leaves also have small teeth or thorns around it’s edges. The aloe plants rosette grows directly at ground level which makes it seem stemless. The flowers are bright orange, tubular and are clustered at the end of a simple leafless stem. ",
    Kingdom: "Plantae",
    Class: "Liliopsida",
    Order: "Asparagales",
    Family: "Asphodelaceae",
    Genus: "Aloe",
  },
  plant7: {
    Image: PlantSeven,
    Name: "Clay Hibiscus",
    Description:
      "The Clay Hibiscus is a bushy, evergreen shrub or small tree with glossy, green leaves. The five petaled flowers are large and trumpet shaped and have prominent anthers with yellow tips. The flowers can be white, pink, orange, red, peach, purple or yellow.",
    Domain: "Tree, Shrub",
    Kingdom: "Plantae",
    Branch: "Tracheobionta",
    Class: "Magnoliopsida",
    Order: "Malvales",
    Family: "Malveceae",
    Genus: "Hibiscuc",
    Species: "Spermatophyta",
  },
  plant8: {
    Image: PlantEight,
    Name: "Garden Croton",
    Description:
      "Codiaeum variegatum is a species of plant in the genus Codiaeum, which is a member of the family Euphorbiaceae. It is native to Indonesia, Malaysia, Australia, and the western Pacific Ocean islands, growing in open forests and scrub",
    Kingdom: "Plantae",
    Class: "Magnoliopsida",
    Order: "Malpighiales",
    Family: "Euphorbiaceae",
    Genus: "Codiaeum",
    Species: "Spermatophyta",
  },
  plant9: {
    Image: PlantNine,
    Name: "Purple Heart",
    Description:
      "Also known as purple queen, purple heart (Setcreasea pallida) is a striking plant with fuzzy, purple, lance-shaped leaves that reach lengths of 7 inches. The stems grow straight up and then lean over, creating a cascading effect. Bright purple flowers appear at the end of the stems in summer and autumn. ",
    Domain: "Forb/Herb",
    Kingdom: "Plantae",
    Branch: "Tracheobionta",
    Class: "Liliopsida",
    Order: "Commelinidae",
    Family: "Commelinaceae",
    Genus: "Tradescantia",
    Species: "Spermatophyta",
  },
  plant10: {
    Image: PlantTen,
    Name: "Jacob’s Coat",
    Description:
      " Acalypha wilkesiana is an evergreen shrub which can grow to 1.8m (6 feet) tall. The stem is erect with many branches. The branches have fine hairs. It has a closely arranged crown. Its pointed oval leaves which are about 12cm (5 inch) long and 5cm (2 inch) wide, are coppery green, mottled and streaked with copper, red and purple.",
    Kingdom: "Plantae",
    Class: "Magnoliopsida",
    Order: "Malpighiales",
    Family: "Euphorbiaceae",
    Genus: "Acalypha",
  },
};
