import React from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import MenuItems from "./MenuItems";
import HeaderImage from "../../Images/HomePage/Header.jpg";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  Image: {
    width: "100%",
  },
}));

function Copyright() {
  return (
    <div style={{ paddingTop: "1%" }}>
      <Typography variant="body2" color="textSecondary" align="center">
        <strong>{"Servey and Landscaping III"}</strong>
        <br />
        {"Mickyle Sewlall - 21711223"}
        <br />
        {"Faylin Govender - 21636960"}
        <br />
        {"Hashlyn Reddy - 21640255"}
        <br />
        {"Kamish Pillay - 21640600"}
      </Typography>
      <br />
    </div>
  );
}

export default function HomePage() {
  const classes = useStyles();

  return (
    <>
      <Box my={0}>
        <img src={HeaderImage} className={classes.Image} />
        <MenuItems />
        <Copyright />
      </Box>
      <Box paddingTop={1} my={0}></Box>
    </>
  );
}
