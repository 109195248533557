import React from "react";
import Container from "@material-ui/core/Container";
import HomePage from "./Content/HomePage/HomePage";

export default function App() {
  return (
    // <Container>
    <HomePage />
    // </Container>
  );
}
