import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  cards: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
      width: "100%", //theme.spacing(90),
      height: theme.spacing(30),
    },
  },
}));

export default function MervinBusinessMenuItems() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const business = {
    Name: "Mervin's Home Improvements",
    Description:
      "Plumbing Requirements,Maintenance Repairs & Installation of Geysers,Baths,Basins,Toilet Pans,Showers,Sinks,Ceiling,Tiling,Renewing Underground Water Pipes to Meter,Building,Electrical,Roofing,Doors,Window Frames,Boundary Walls,Plastering & Painting,Built-in-Cupboards,Computer and Stove Repairs & Washing Machine Repairs,High Pressure ,Steam Cleaning,Shop fittings,Swimming Pools & Rock Work." +
      "With 20 Years of Experience in the Trade anyone wishing to contact me fo advice feel free to view my contact details.",
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="Home" {...a11yProps(0)} />
          <Tab label="About Us" {...a11yProps(1)} />
          <Tab label="Gallery" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        Home Page
        <div className={classes.cards}>
          {/* <PaperCard1 />
          <PaperCard2 /> */}
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <List>
          <ListItem button>
            <ListItemText primary="Contact Number" secondary="082 509 0009" />
          </ListItem>
          <Divider />
          <ListItem button>
            <ListItemText
              primary="Domestic and Industrial"
              secondary={business.Description}
            />
          </ListItem>
        </List>
      </TabPanel>
      <TabPanel value={value} index={2}>
        Gallery
        <div className={classes.cards}></div>
      </TabPanel>
    </div>
  );
}
