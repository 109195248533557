import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";

const useScript = (url) => {
  const script = document.createElement("script");

  script.src = url;
  script.async = false;

  return script;
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  iframe: {
    width: "100%",
    height: "70vh",
    overflow: "hidden",
  },
  cards: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
      width: "100%", //theme.spacing(90),
      height: theme.spacing(30),
    },
  },
}));

export default function GamesMenuItems() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={value}
          variant="scrollable"
          scrollButtons="auto"
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="Tetris" {...a11yProps(0)} />
          <Tab label="Fruit Salad" {...a11yProps(1)} />
          <Tab label="Minefield" {...a11yProps(2)} />
          <Tab label="2048 HP" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <iframe
          src="https://www.baptistebrunet.com/games/tetris_js/"
          className={classes.iframe}
          scrolling="no"
          frameborder="0"
          allowtransparency="true"
        ></iframe>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <iframe
          src="http://www.baptistebrunet.com/games/fruit_salad/"
          className={classes.iframe}
          scrolling="no"
          frameborder="0"
          allowtransparency="true"
        ></iframe>
      </TabPanel>

      <TabPanel value={value} index={2}>
        <iframe
          src="http://www.baptistebrunet.com/games/minefield/"
          className={classes.iframe}
          scrolling="no"
          frameborder="0"
          allowtransparency="true"
        ></iframe>
      </TabPanel>

      <TabPanel value={value} index={2}>
        <iframe
          src="https://yaksgames.com/games/2048-harry-potter/G99CB96D59"
          className={classes.iframe}
          scrolling="no"
          frameborder="0"
          allowtransparency="true"
        ></iframe>
      </TabPanel>
    </div>
  );
}
