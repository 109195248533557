import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import SendIcon from "@material-ui/icons/Send";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StarBorder from "@material-ui/icons/StarBorder";
import Typography from "@material-ui/core/Typography";
import MervinBusinessContent from "../BusinessPackages/MervinBusiness/MervinBusinessContent";

import {
  createMuiTheme,
  responsiveFontSizes,
  MuiThemeProvider,
} from "@material-ui/core";
import ToolsContent from "../BusinessPackages/Tools/ToolsContent";
import GamesContent from "../BusinessPackages/Games/GamesContent";

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 300,
    height: "100vh",
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(2),
  },
}));

export default function MenuNavigation() {
  const classes = useStyles();
  const [constructionOpen, setConstructionOpen] = React.useState(false);

  const handleClickConstruction = () => {
    setConstructionOpen(!constructionOpen);
  };

  const handleClickTools = () => {
    return <ToolsContent />;
  };

  return (
    <MuiThemeProvider theme={theme}>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            <Typography variant="h5">Categories</Typography>
          </ListSubheader>
        }
        className={classes.root}
      >
        <ToolsContent />
        <ListItem button>
          <ListItemText primary="Banking" secondary="Coming soon" />
        </ListItem>
        <GamesContent />
        <ListItem button onClick={handleClickConstruction}>
          <ListItemText primary="Construction" />
          {constructionOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={constructionOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested}>
              <MervinBusinessContent />
            </ListItem>
          </List>
        </Collapse>
      </List>
    </MuiThemeProvider>
  );
}
